<template>
  <div class="box">
    <!-- 头部 -->
    <headers></headers>
    <el-carousel :interval="3000">
      <el-carousel-item>
        <img src="@/assets/homePage/新闻资讯banner.png" class="images">
      </el-carousel-item>
      <el-carousel-item>
        <img src="@/assets/homePage/视频banner.png" class="images">
      </el-carousel-item>
    </el-carousel>
    <!-- 内容区域 -->
    <div class="content_top">
      <div class="b_1">
        <span v-for="item in video_list" :key="item.id" :class="{videoClass:item.id == video_id}"
          style="margin-right: 20px;" @click="videoSpan(item.id)">
          {{item.title}}
        </span>
      </div>
    </div>
    <div class="content" v-show="video_id==1">
      <div class="content_1" v-for="item in dataList" :key="item.id" @click="routePush(item.id)">
        <div>
          <img :src="item.url" class="imgs" />
        </div>
        <p style="font-size: 1.1rem;">{{ item.name }}</p>
        <div class="box" style="margin-left: 1.3rem;color:#777;">{{ item.date }}</div>
      </div>
    </div>
    <div class="page_data">
      <el-pagination background style="margin-left: 50%;transform: translateX(-50%);" v-model:currentPage="currentPage"
        v-show="video_id==1" v-model:page-size="pageSize" :page-sizes="[9, 18, 27, 36]" :disabled="disabled"
        layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :default-page-size="12" />
    </div>
    <div class="content_button" v-show="video_id==2">
      <div class="video" v-for="item in video_data" :key="item.id">
        <video controls controlslist="nodownload" οncοntextmenu="return false">
          <source :src="item.url" type="video/mp4">
        </video>
        <h2>{{item.name}}</h2>
        <p>{{item.title}}</p>
      </div>
    </div>
    <!-- 底部 -->
    <foot></foot>
  </div>
  <div class="_div">
    <!-- 头部 -->
    <move_header></move_header>
    <!-- 轮播图 -->
    <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img src="@/assets/homePage/新闻资讯banner.png" style="width: 100%;">
      </van-swipe-item>
      <van-swipe-item>
        <img src="@/assets/homePage/视频banner.png" style="width: 100%;">
      </van-swipe-item>
    </van-swipe>
    <!-- 中间内容区域 -->
    <div class="table_T">
        <span v-for="item in video_list" :key="item.id" :class="{videoClass:item.id == video_id}"
          style="margin-right: 20px;" @click="videoSpan(item.id)">
          {{item.title}}
        </span>
      </div>
    <div class="content" v-show="video_id==1">
      <div class="box_center" v-for="item in move_dataList" :key="item.id" @click="routePush(item.id)">
        <img :src="item.url" alt="">
        <div class="pp">
          <p style="font-size: 2.5rem;">{{ item.name }}</p>
          <p class="p_2">{{ item.date }}</p>
        </div>
      </div>
    </div>
    <div class="content_button" v-show="video_id==2">
      <div class="video" v-for="item in video_data" :key="item.id">
        <video controls controlslist="nodownload" οncοntextmenu="return false">
          <source :src="item.url" type="video/mp4">
        </video>
        <h2>{{item.name}}</h2>
        <p>{{item.title}}</p>
      </div>
    </div>
    <!-- 底部 -->
    <move_foot></move_foot>
  </div>
</template>

<script>
import foot from "../components/foot.vue";
import headers from "../components/header.vue";
import { reactive, toRefs, onMounted, ref } from "vue";
import move_foot from "../move_components/move_foot.vue";
import move_header from "../move_components/move_header.vue";
import { useRouter, useRoute } from 'vue-router' //引入useRouter
import { debounce } from '@/utils/debounce.js'
export default {
  components: { foot, headers, move_foot, move_header },

  setup() {
    const router = useRouter()
    const route = useRoute()
    const currentPage = ref(null)//当前页数
    const move_currentPage = ref(null)//当前页数
    const pageSize = ref(9) //当前页展示个数
    const move_pageSize = ref(8) //当前页展示个数
    const total = ref(null) //总数
    const state = reactive({
      _dataList: [
        { id: 0, date: '', name: '盛泰有约·家庭趣欢享||家庭开放日活动', url: require('@/assets/news/小图/0.png') },
     

        { id: 64, date: '2023年6月25日', name: '【盛泰资讯】重庆市政协主席唐方裕莅临盛泰光电科技股份有限公司参观调研', url: require('@/assets/news/小图/64.png') },
        { id: 63, date: '2023年6月17日', name: '“重庆市企业创新奖”出炉，盛泰光电科技股份有限公司榜上有名', url: require('@/assets/news/小图/63.png') },
        { id: 61, date: '2023年6月13日', name: '观念转换，科学推进——2023重庆公司六西格玛项目顺利启动', url: require('@/assets/news/小图/61.png') },
        { id: 62, date: '2023年6月5日', name: '【盛泰团建】打造优秀团队，共创盛泰辉煌', url: require('@/assets/news/小图/62.png') },
        { id: 60, date: '2023年6月5日', name: '【盛泰资讯】重庆市检察院检察长时侠联来盛泰公司调研', url: require('@/assets/news/小图/60.png') },
        { id: 59, date: '2023年6月2日', name: '“民生情怀浓 惠民更有感”关爱大足困难残疾人公益活动', url: require('@/assets/news/小图/59.png') },
        { id: 58, date: '2023年5月29日', name: '腾云乘势，5限可能—盛泰光电重庆公司5周年庆典活动圆满举行', url: require('@/assets/news/小图/58.png') },
        { id: 57, date: '2023年5月25日', name: '2023年“跃动篮球，绽放激情”盛泰光电夏季男子篮球赛完美收官！', url: require('@/assets/news/小图/57.png') },
        { id: 56, date: '2023年5月24日', name: '为未来深入合作奠定良好基础', url: require('@/assets/news/小图/56.png') },
        { id: 55, date: '2023年4月25日', name: '大足区委书记陈一清率队观摩盛泰光电“5G＋工业互联网”项目', url: require('@/assets/news/小图/55.png') },
        { id: 54, date: '2023年4月19日', name: '群星之秀，川流不息——2023年第一季度“盛泰之星”颁奖仪式圆', url: require('@/assets/news/小图/54.png') },
        { id: 53, date: '2023年4月8日', name: '重庆市经信委金融处王逸飞处长到盛泰座谈交流', url: require('@/assets/news/小图/53.png') },
        { id: 52, date: '2023年4月1日', name: '2023年第一季度IDL员工座谈会圆满结束', url: require('@/assets/news/小图/52.png') },
        
     
        { id: 36, date: '2022年12月22日', name: '铆劲抓生产·打好“收官战”', url: require('@/assets/news/小图/36.png') },
        { id: 35, date: '2022年12月22日', name: '秋招季|高校宣讲会圆满收官，场场精彩', url: require('@/assets/news/小图/35.png') },
        { id: 34, date: '2022年12月5日', name: '盛泰活动|守护双桥生态·绿色环保盛泰行 捡拾活动顺利举行', url: require('@/assets/news/小图/34.png') },
        { id: 33, date: '2022年11月29日', name: '同心抗“疫”，感谢有你----致奋战在盛泰光电疫情防控一线全体勇士的感谢信', url: require('@/assets/news/小图/33.png') },
        { id: 32, date: '2022年11月27日', name: '青春无畏·逐梦扬威，盛泰光电2022年冬季职工篮球赛圆满结束', url: require('@/assets/news/小图/32.png') },
        { id: 31, date: '2022年11月26日', name: '同心“战”疫，共克时艰|爱护宿舍环境，从你我做起', url: require('@/assets/news/小图/31.png') },
        { id: 30, date: '2022年11月24日', name: '大足 盛泰光电入选国家知识产权优势企业', url: require('@/assets/news/小图/30.png') },
        { id: 29, date: '2022年11月15日', name: '盛泰光电举办“凝心聚力，向新征程”拔河比赛圆满结束', url: require('@/assets/news/小图/29.png') },
       { id: 28, date: '2022年10月27日', name: '创新是第一动力 人才是第一资源', url: require('@/assets/news/小图/28.png') },
        { id: 27, date: '2022年10月21日', name: '盛泰光电第二届羽毛球比赛圆满结束', url: require('@/assets/news/小图/27.png') },
        { id: 26, date: '2022年10月15日', name: '江西省人大常委会副主任张小平来江西盛泰精密光学有限公司开展调研', url: require('@/assets/news/小图/26.png') },
        { id: 25, date: '2022年9月13日', name: '2022年内部讲师交流会圆满举行', url: require('@/assets/news/小图/25.png') },
        { id: 21, date: '2022年9月6日', name: '盛泰资讯|吉林大学重庆研究院到盛泰光科走访交流', url: require('@/assets/news/小图/21.jpg') },
        { id: 23, date: '2022年8月30日', name: '盛泰资讯|盛泰光电上榜2022重庆制造业企业100强', url: require('@/assets/news/小图/23.jpg') },
        { id: 22, date: '2022年8月30日', name: '盛泰资讯|大足区组织部副部长、老干局局长张玛莉一行领导莅临我司调研', url: require('@/assets/news/小图/22.jpg') },
        { id: 20, date: '2022年8月30日', name: '盛泰资讯|盛泰光电捐资助学，情暖高升莘莘学子', url: require('@/assets/news/小图/20.jpg') },
        { id: 19, date: '2022年8月30日', name: '盛泰资讯|大足区经信委开展复工复产调研工作，访企业、听心声、问需求、话发展', url: require('@/assets/news/小图/19.jpg') },
        { id: 24, date: '2022年8月22日', name: '“悦享盛夏·鱼乐无穷”2022盛泰第一届“盛泰杯”钓王比赛圆满结束', url: require('@/assets/news/小图/24.png') },
        { id: 17, date: '2022年8月17日', name: '江西盛泰与英恒达成战略合作，共同推进中国智能汽车产业链发展', url: require('@/assets/news/小图/17.jpg') },
        { id: 16, date: '2022年7月20日', name: '第六届“渝创渝新”创业创新大赛决赛我司项目荣获大赛制造业组三等奖', url: require('@/assets/news/小图/16.jpg') },
        { id: 15, date: '2022年7月13日', name: '盛泰光电获“川渝聚力，创汇大足”创业创新大赛决赛第一名', url: require('@/assets/news/小图/15.jpg') },
        { id: 14, date: '2022年7月7日', name: '2022大足区新任处级领导干部一行参观我司', url: require('@/assets/news/小图/14.jpg') },
        { id: 13, date: '2022年7月5日', name: '盛泰资讯|盛泰光电成功跻身“2021重庆制造业民营企业100强”第28名', url: require('@/assets/news/小图/13.jpg') },
        { id: 11, date: '2022年6月25日', name: '盛泰资讯|成都市新津区税务局和商务会展局一行到访', url: require('@/assets/news/小图/11.jpg') },
        { id: 18, date: '2022年6月17日', name: '国际瑜伽日2022 | IDY2022 Curtain Raiser events continue in South China', url: require('@/assets/news/小图/18.png') },
        { id: 12, date: '2022年6月16日', name: '盛泰资讯|大足区人大常委机关离退休老干部一行莅临盛泰公司参观', url: require('@/assets/news/小图/12.jpg') },
        { id: 10, date: '2022年6月10日', name: '盛泰光电进入“中国创翼”创新创业大赛重庆赛区制造业专场10强', url: require('@/assets/news/小图/10.jpg') },
        { id: 9, date: '2022年6月7日', name: '团市委一行莅临公司参观考察', url: require('@/assets/news/小图/9.jpg') },
        { id: 1, date: '2022年5月12日', name: '喜讯！盛泰光电何阿强上榜“最美青年技能人才”', url: require('@/assets/news/小图/1.jpg') },
        { id: 2, date: '2022年2月21日', name: '杨烈常委一行莅临我司调研基层社会治理和平安建设工作', url: require('@/assets/news/小图/2.jpg') },
        { id: 3, date: '2022年1月12日', name: '以科技加持， 以可靠助力，盛泰公司可靠性实验分析中心剪彩仪式圆满举行', url: require('@/assets/news/小图/3.jpg') },
        { id: 4, date: '2021年11月5日', name: '重庆新闻联播到我司进行专题采访', url: require('@/assets/news/小图/4.jpg') },
        { id: 5, date: '2021年10月9日', name: '利剑出刃，2020年营收约30亿斩获2021重庆民营、制造双百强！', url: require('@/assets/news/小图/5.jpg') },
        { id: 6, date: '2021年10月9日', name: '赋能发展，砥砺前行，大足区区委书记陈一清调研重庆盛泰光电有限公司', url: require('@/assets/news/小图/6.jpg') },
        { id: 7, date: '2021年10月1日', name: '出货量全球第四！盛泰光电荣登人民日报头刊', url: require('@/assets/news/小图/7.jpg') },
        { id: 8, date: '2021年9月29日', name: '做大做强产业集群 切实保障安全生产 区领导到双桥经开区调研重点企业检查安全生产', url: require('@/assets/news/小图/8.jpg') },
      ],
      dataList: [],
      move_dataList: [],
      video_list: [
        { id: 1, title: '新闻资讯' },
        { id: 2, title: '盛泰映像' },
      ],
      video_data:[
        {id:1,url:`https://www.shine-optics.com/video/ows/yoga.mp4`,name:'国际瑜伽日 2022',title:'盛泰集团在6月的国际瑜伽日，组织一场迎接“世界瑜伽日”的活动，旨在让员工，放松身心，感受瑜伽的带给身体和心灵的福祉，远离都市疾病困扰。'},
        {id:2,url:`https://www.shine-optics.com/video/ows/overview.mp4`,name:'走进盛泰光电重庆总部',title:'盛泰光电在2006年7月成立于深圳，在成长壮大中于2018年4月成立重庆盛泰光电有限公司。公司一直保持快速增长，2020年实现产值36亿元，提供就业岗位2000余个；2021年产值超过40亿元，未来力争在3-4年内实现百亿产值。'},
      ],
      video_id: 1,

    });
    let methods = {
      // 点击视频
      videoSpan(id) {
        state.video_id = id
      },
      routePush(id) {
        const href = router.resolve({ //使用resolve
          name: 'zhNewsId',    //这里是跳转页面的name
          path: '/zh/newsId',
          query: { id }
        })
        window.open(href.href, '_blank')
      },
      // pagesize改变触发
      handleSizeChange(val) {
        currentPage.value = 1
        pageSize.value = val
        state.dataList = state._dataList.slice(0, val)
      },
      // 当前页数改变触发
      handleCurrentChange(val) {
        window.scrollTo(0, 0)
        currentPage.value = val
        console.log('pagesize', val);
        //state._dataList，state.dataList是目前表格绑定的数据
        state.dataList = state._dataList.slice(
          (currentPage.value - 1) * pageSize.value,
          currentPage.value * pageSize.value
        );
      },
      setList() {
        let begin = (move_currentPage.value - 1) * move_pageSize.value;
        let end = move_currentPage.value * move_pageSize.value;
        state.move_dataList = [...state.move_dataList, ...state._dataList.slice(begin, end)];
        // state.move_dataList = data;
      }
    }
    onMounted(() => {
      let DATE = 1000
      total.value = state._dataList.length
      state.dataList = state._dataList.slice(0, 9)
      state.move_dataList = state._dataList.slice(0, 8)
      debounce(window.onscroll = () => {
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        //变量windowHeight是可视区的高度
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        //变量scrollHeight是滚动条的总高度
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        //滚动条到底部的条件(距底部20px时触发加载)

        if (
          scrollTop + windowHeight >= scrollHeight - 100
        ) {
          move_currentPage.value++
          methods.setList()
        }

      }, DATE)
      if (route.query && route.query.id == 2) {
        state.video_id = 2
      }
    })
    return {
      ...toRefs(state), ...methods, currentPage, pageSize, total, move_pageSize, move_currentPage
    };
  },
};
</script>

<style scoped lang="less">
.content_1:hover .imgs {
  transition: all 0.6s;
  transform: scale(1.03);

}

.box {
  width: 100%;

  .page_data {
    margin: 50px auto;
    max-width: 1920px;
    width: 70%;
    display: flex;

  }

  .content_button {
    margin: 50px auto;
    max-width: 1920px;
    width: 70%;

    .video {
      margin-left: 50%;
      transform: translateX(-50%);

      video {
        width: 800px;
      }
    }
  }

  .content_top {

    margin: 50px auto;
    max-width: 1920px;
    width: 70%;
    height: 20px;

    .b_1 {
      float: right;
      margin-right: 50px;
      font-size: 1rem;

      span {
        cursor: pointer;
      }
    }

    .videoClass {
      border-bottom: 3px solid red;
      padding: 10px 0;
    }
  }

  .content {
    margin: 50px auto;
    max-width: 1920px;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;

    .content_1 {
      flex: 1;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      height: 350px;
      min-width: 30%;
      max-width: 24%;
      margin: 20px;
      border-radius: 8px 8px 0 0;
      background-color: #E9E9E9;
      overflow: hidden;

      .box {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30px;

      }

      div {
        border-radius: 8px;
        overflow: hidden;
        height: 250px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.6s;
      }

      img:hover {}

      p {
        padding: 0 20px;
        cursor: pointer;
      }

      p:hover {
        color: red;
        transform: scaleZ(2);
      }
    }
  }
}

::v-deep .el-card {
  border-radius: 8px;
}

::v-deep .el-carousel {
  max-width: 1920px;
  margin: 0 auto;
  overflow-y: hidden;
  height: auto;
  max-height: 600px;
  // max-height: 700px;
}

::v-deep .el-carousel__container {
  height: 100% !important;
  min-height: 500px;
}

.el-carousel {
  max-width: 1920px;
  margin: 0 auto;
}

::v-deep .el-card {
  border-radius: 8px;
}

.images {
  width: 100%;
  height: auto;
  max-height: 600px
}

// 小屏幕区域
@media only screen and (min-width: 767px) {
  .box {
    display: block;
  }

  ._div {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .box {
    display: none;
  }

  ._div {
    display: block;
    overflow-x: hidden;

    .content {
      width: 100%;
      padding: 30px;
      display: flex;
      box-sizing: border-box;
      flex-wrap: wrap;

      .box_center:hover .pp {
        color: red;
      }

      .box_center {
        cursor: pointer;
        box-sizing: border-box;
        padding: 30px;
        flex: 1;
        max-width: 50%;
        min-width: 50%;
        height: 900px;

        img {
          width: 100%;
          height: 600px;
          border-radius: 15px;
          overflow: hidden;
        }

        .pp {
          position: relative;
          height: 240px;
          background-color: #E9E9E9;
          margin-top: -35px;
          // font-size: 43px;
          padding-top: 30px;
          padding-left: 50px;
          padding-right: 20px;

          .p_2 {
            position: absolute;
            margin-top: -15px;
            font-size: 35px;
            bottom: 0;
            color: #666;
          }
        }

      }
    }
  }
  .table_T{
    text-align: center;
    span{
      font-size: 3.75rem;
      cursor: pointer;
    }
  }
  .videoClass {
      border-bottom: 10px solid red;
      padding: 10px 0;
    }
    .content_button {
    margin: 50px auto;
      padding: 10px;
    .video {
      h2{
        font-size: 4rem;
      }
      p{
        font-size: 40px;
      }
      video {
        width: 100%;
      }
    }
  }
}
</style>